<template>
  <ion-page id="main-content">
    <Header page="pages" />


    <!-- Page Content -->
    <ion-content :fullscreen="true">
      <ProfileAvatars profile-type="business" :limit="0" view="full" :scene-id="sceneId" />
    </ion-content>

    <!-- put the tabs in ion-footer!!! -->
    <ion-footer v-if="device != 'web'">
      <Tabs page="pages" />
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import ProfileAvatars from '../components/ProfileAvatars.vue';
import doAuth from '../services/auth';
import Tabs from '../components/Tabs.vue';
import Header from '../components/Header.vue';
import { useRouter } from 'vue-router';
import { isPlatform } from '@ionic/vue';
import { useStore } from "vuex";

export default defineComponent({
  name: 'Pages',
  components: {
    IonPage, IonContent, IonFooter, Tabs, Header, ProfileAvatars
  }, 
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const view = ref('feed');
    const device = ref('web');
    const sceneId = computed(() => store.state.sceneId);

    onMounted(() => {
      // App
      if(isPlatform('ios') === true || isPlatform('android') === true)  {
        device.value = 'mobile-app'
      } 
      // Web
      else {
        device.value = 'web'
      }
    })

    function updateRoute(r) {
      router.push(r)
    }



    return {
      updateRoute, router, view, device, sceneId
    }
  }

});
</script>

<style scoped>





</style>
